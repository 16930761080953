.skilldrop .mdl-shadow--2dp {
  box-shadow: none;
  margin-top: 20px;
}

.firebaseui-id-page-password-sign-in .firebaseui-card-header {
  display: none;
}

.skilldrop .firebaseui-card-content,
.skilldrop .firebaseui-card-actions,
.skilldrop .firebaseui-card-footer
{
  padding-left: 0;
  padding-right: 0;
}
